.complete {
  text-decoration: line-through;
  opacity: 0.4;
}

.todo-row {
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  align-items: center;
  margin: 4px auto;
  padding: 16px;
  border-radius: 5px;
  width: 90%;
}

.icons {
  display: flex;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.delete-icon {
  margin-right: 5px;
}
