.garbageHover:hover {
  opacity: 0.8;
}

.weatherHover:hover {
  opacity: 0.8;
}

.contactHover:hover {
  opacity: 0.8;
}
